function closeCollapse(divToHide) {
  document.getElementById(divToHide).classList.remove("show");
}

// show/hide password in password input in login page

showPassword = false

  function toggleShowPassword() {
    var input = document.getElementById('password');
    var iconShow = document.getElementById('password-icon-visible');
    var iconHide = document.getElementById('password-icon-invisible');
    this.showPassword = !this.showPassword;

    if(this.showPassword === true) {
      input.setAttribute('type','text');
      iconHide.classList.remove('d-none');
      iconShow.classList.add('d-none');

    } else if (this.showPassword === false)  {
      input.setAttribute('type','password');
      iconHide.classList.add('d-none');
      iconShow.classList.remove('d-none');

    };
  };
